// ** Initial State
const initialState = {
    allData: [],
    data: [],
    selectedServiceUsing: null,
    addServiceUsingPrice: 0
}

const admins = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_SERVICE_USINGS':
            return { ...state, allData: action.data, data: action.data, selectedServiceUsing: null }
        case 'FILTER_SERVICE_USINGS':
            return { ...state, data: action.data }
        case 'GET_SERVICE_USING':
            return { ...state, selectedServiceUsing: action.selectedServiceUsing }
        case 'GET_ADD_SERVICE_USING_PRICE':
            return { ...state, addServiceUsingPrice: action.price }
        case 'UPDATE_SERVICE_USING':
            return { ...state, selectedServiceUsing: null }
        case 'ADD_SERVICE_USING':
            return { ...state }
        default:
            return { ...state }
    }
}
export default admins
