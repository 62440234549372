import axios from 'axios'

require('dotenv').config()

const apiConfig = {
    url: process.env.REACT_APP_BACKEND_ENDPOINT === "production" ? "https://esprom.cz/api" : "https://aso.development.anafra.net/api"
}

export const api = {
    async get (endPoint, data)
    {
        const response = await axios.get(`${apiConfig.url}/${endPoint}`, data).catch((err) => {
            throw err
        })

        return response
    },
    async post (endPoint, data)
    {
        const response = await axios.post(`${apiConfig.url}/${endPoint}`, data).catch((err) => {
            throw err
        })
        
        return response
    },
    async put (endPoint, data)
    {
        const response = await axios.put(`${apiConfig.url}/${endPoint}`, data).catch((err) => {
            throw err
        })
        
        return response
    },
    async delete (endPoint, data)
    {
        const response = await axios.delete(`${apiConfig.url}/${endPoint}`, { data }).catch((err) => {
            throw err
        })
        
        return response
    }
}

export default apiConfig