// ** React Imports
import { forwardRef, Fragment } from 'react'

import Proptypes from 'prop-types'

import Avatar from '@components/avatar'

import { AlertCircle, Info } from 'react-feather'

const Toast = forwardRef((props, ref) => {
    const {
        color,
        className,
        header,
        text,
        size,
        ...rest
    } = props

    let icon = <AlertCircle size={size}/>

    if (color === "success")
    {
        icon = <Info size={size} />
    }

    return (
        <Fragment>
        <div className='toastify-header'>
            <div className='title-wrapper'>
                <Avatar size={size} color={color} icon={icon}/>
                <h6 className={`toast-title text-${color} font-weight-bold`}>{header}</h6>
            </div>
        </div>
        <div className='toastify-body'>
            <span>{text}</span>
        </div>
        </Fragment>
    )
})

Avatar.propTypes = {
    className: Proptypes.string,
    header: Proptypes.string,
    text: Proptypes.string,
    icon: Proptypes.node,
    size: Proptypes.number,
    color: Proptypes.oneOf([
        'primary',
        'secondary',
        'success',
        'danger',
        'info',
        'warning',
        'dark'
    ])
}

Toast.defaultProps = {
    size: 12
}

export default Toast