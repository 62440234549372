// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import users from './users'
import pickups from './pickups'
import pickupPlaces from './pickup-places'
import services from './services'
import serviceUsings from './service-usings'
import servicePlans from './service-plans'
import calendar from './calendar'
import depot from './depot'
import statistics from './statistics'
import offers from './offers'

import chat from '@src/views/apps/chat/store/reducer'
import todo from '@src/views/apps/todo/store/reducer'
import email from '@src/views/apps/email/store/reducer'
import invoice from '@src/views/apps/invoice/store/reducer'
//import calendar from '@src/views/aso/calendar/admin/store/reducer'
import ecommerce from '@src/views/apps/ecommerce/store/reducer'
import dataTables from '@src/views/tables/data-tables/store/reducer'

const rootReducer = combineReducers({
  auth,
  todo,
  chat,
  email,
  users,
  navbar,
  layout,
  invoice,
  calendar,
  offers,
  ecommerce,
  dataTables,
  statistics,
  depot,
  pickupPlaces,
  services,
  pickups,
  serviceUsings,
  servicePlans
})

export default rootReducer
