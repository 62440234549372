// ** Initial State
const initialState = {
    allData: [],
    data: [],
    selectedUser: null,
    selectedUserPickupPlaces: [],
    selectedCustomerUsers: [],
    drivers: [],
    customers: []
}

const admins = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_USERS':
            return { ...state, allData: action.data, data: action.data, selectedUser: null }
        case 'FILTER_USERS':
            return { ...state, data: action.data }
        case 'GET_USER':
            return { ...state, selectedUser: action.selectedUser }
        case 'GET_CUSTOMER_USERS':
            return { ...state, selectedCustomerUsers: action.selectedCustomerUsers }
        case 'GET_CUSTOMER_PICKUP_PLACES':
            return { ...state, selectedUserPickupPlaces: action.selectedUserPickupPlaces }
        case 'ADD_USER':
            return { ...state }
        case 'ADD_PICKUP_PLACE_TO_CUSTOMER':
            return { ...state }
        case 'DELETE_PICKUP_PLACE_FROM_CUSTOMER':
            return { ...state }
        case 'DELETE_USER':
            return { ...state }
        case 'UPDATE_USER':
            return { ...state, selectedUser: null }
        case 'FETCH_DRIVERS':
            return { ...state, drivers: action.data }
        case 'FETCH_CUSTOMERS':
            return { ...state, customers: action.data }
        default:
            return { ...state }
    }
}
export default admins
