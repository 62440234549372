// ** Initial State
const initialState = {
    data: [],
    selectedService: null,
    cost: 0,
    pickupCount: 0,
    serviceUsingCount: 0
}

const admins = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_STATISTICS':
            return { ...state, cost: action.cost, pickupCount: action.pickupCount, serviceUsingCount: action.serviceUsingCount }
        case 'FETCH_AGGREGATED_SERVICE_USINGS':
            return { ...state, data: action.data }
        default:
            return { ...state }
    }
}
export default admins
