// ** Initial State
const initialState = {
    data: []
}

const admins = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_OFFERS':
            return { ...state, data: action.data }
        default:
            return { ...state }
    }
}
export default admins
