/* eslint-disable */

import { isLoggedUserAdmin } from "@utils"

const YBug = () => {
    const id = "qa7c8kcy7n02waj5csnp"

    window.ybug_settings = { id }

    console.log(isLoggedUserAdmin())

    if (isLoggedUserAdmin())
    {
        const script = document.createElement("script");

        script.type = "text/javascript"
        script.src = "https://widget.ybug.io/button/qa7c8kcy7n02waj5csnp.js";
        script.async = true;

        document.body.appendChild(script);
    }
    else
    {
        document.querySelector("#__ybug-launcher")?.remove()
    }

    return null
}

export default YBug