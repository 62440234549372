// ** Initial State
const initialState = {
    allData: [],
    data: [],
    selectedService: null,
    selectedServicePriceLists: []
}

const admins = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_SERVICES':
            return { ...state, allData: action.data, data: action.data, selectedService: null }
        case 'FILTER_SERVICES':
            return { ...state, data: action.data }
        case 'GET_SERVICE':
            return { ...state, selectedService: action.selectedService }
        case 'GET_SERVICE_PRICE_LISTS':
            return { ...state, selectedServicePriceLists: action.selectedServicePriceLists }
        case 'ADD_SERVICE':
            return { ...state }
        case 'DELETE_SERVICE':
            return { ...state }
        case 'UPDATE_SERVICE':
            return { ...state, selectedService: null }
        default:
            return { ...state }
    }
}
export default admins
