// ** Initial State
const initialState = {
    allData: [],
    data: [],
    selectedPickupPlace: null,
    selectedPickupPlaceCustomers: []
}

const admins = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_PICKUP_PLACES':
            return { ...state, allData: action.data, data: action.data, selectedPickupPlace: null }
        case 'FILTER_PICKUP_PLACES':
            return { ...state, data: action.data }
        case 'GET_PICKUP_PLACE':
            return { ...state, selectedPickupPlace: action.selectedPickupPlace }
        case 'GET_PICKUP_PLACE_CUSTOMERS':
            return { ...state, selectedPickupPlaceCustomers: action.selectedPickupPlaceCustomers }
        case 'ADD_PICKUP_PLACE':
            return { ...state }
        case 'ADD_CUSTOMER_TO_PICKUP_PLACE':
            return { ...state }
        case 'DELETE_CUSTOMER_FROM_PICKUP_PLACE':
            return { ...state }
        case 'DELETE_PICKUP_PLACE':
            return { ...state }
        case 'UPDATE_PICKUP_PLACE':
            return { ...state, selectedPickupPlace: null }
        default:
            return { ...state }
    }
}
export default admins
