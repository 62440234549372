// **  Initial State
const initialState = {
  userData: {}
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN':
            {
                return {
                    ...state,
                    userData: action.data,
                    [action.config.storageTokenKeyName]: action[action.config.storageTokenKeyName],
                    [action.config.storageRefreshTokenKeyName]: action[action.config.storageRefreshTokenKeyName]
                }
            }
        case 'SET_PASSWORD':
            return { ...state }
        case 'FORGOT_PASSWORD':
            return { ...state }
        case 'LOGOUT':
            const obj = { ...action }
            delete obj.type
            return { ...state, userData: {}, ...obj }
        default:
        return state
    }
}

export default authReducer
