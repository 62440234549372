// ** Initial State
const initialState = {
    allData: [],
    data: [],
    selectedService: null,
    url: null
}

const admins = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_PICKUPS':
            return { ...state, allData: action.data, selectedService: null }
        case 'FETCH_DIRECTION_URL':
            return { ...state, url: action.data }
        case 'FILTER_PICKUPS':
            return { ...state, data: action.data }
        case 'GET_PICKUP':
            return { ...state, selectedService: action.selectedService }
        case 'UPDATE_PICKUP':
            return { ...state, selectedService: null }
        case 'ADD_STATE':
            return { ...state }
        default:
            return { ...state }
    }
}
export default admins
