import { api } from "@configs/apiConfig"
import moment from "moment"

export const FILTER_TYPES = {
    ALL: 1,
    TODAY: 2,
    WEEK: 3,
    TOMORROW: 4,
    PAST: 5
}

// ** Get all pickups
export const getAllPickups = (filter) => {
    return async dispatch => {
        if (filter?.fromDate)
        {
            filter.fromDate = moment(filter.fromDate).format("yyyy-MM-DD")
        }

        if (filter?.toDate)
        {
            filter.toDate = moment(filter.toDate).format("yyyy-MM-DD")
        }

        const response = await api.get(`pickups`, {
            params: {
                fromDate: filter?.fromDate, toDate: filter?.toDate
            }
        })

        const pickups = response.data.sort((a, b) => new Date(a.date) - new Date(b.date))

        dispatch({
            type: 'FETCH_PICKUPS',
            data: pickups
        })
    }
}

// ** Get direction URL
export const getDirectionURL = () => {
    return async dispatch => {
        const response = await api.get(`pickups/directionsUrl`)

        const url = response.data.url

        dispatch({
            type: 'FETCH_DIRECTION_URL',
            data: url
        })
    }
}

// ** Filter data gathered from getAllServices()
export const filterData = (params) => {
    return async dispatch => {
        const pickups = params.data.filter((pickup) => (
                pickup.pickupPlace.name.toLowerCase().includes(params.filter.toLowerCase())
                || pickup.customer.customerName.toLowerCase().includes(params.filter.toLowerCase())
                || (pickup.driver.id && pickup.driver.fullname.toLowerCase().includes(params.filter.toLowerCase()))
            )
        )

        dispatch({
            type: 'FILTER_PICKUPS',
            data: pickups
        })
    }
}

// ** Asigns driver to pickups
export const pickupsAssignDriver = (data) => {
    return async dispatch => {
        const pickupIds = data.selectedRows.map((selectedRow) => selectedRow.id)

        await api.post("pickups/assign", { driverId: data.driverId, pickupIds })

        dispatch({
            type: 'ASIGN_DRIVERS'
        })
    }
}

// ** Get pickup with specified id
export const getPickup = (id) => {
    return async dispatch => {
        const response = await api.get(`pickups/${id}`)
        const pickup = response.data

        /*if (!pickup.active)
        {
            throw "DEACTIVATED"
        }*/

        dispatch({
            type: 'GET_PICKUP',
            selectedPickup: pickup
        })
    }
}

// ** Adds state to pickup
export const addState = (data) => {
    return async dispatch => {
        await api.post(`pickups/${data.id}/states`, data)

        dispatch({
            type: 'ADD_STATE'
        })
    }
}

// ** Updates pickup with specified id
export const updatePickup = (data) => {
    return async dispatch => {
        await api.put(`pickups/${data.id}`, data).then(() => {
            dispatch({ type: "UPDATE_PICKUP" })

            /*data.customers.forEach(async (customer) => {
                const shouldAdd = data.selectedCustomers.every((selectedCustomer) => selectedCustomer.id !== customer.value)
                
                if (shouldAdd)
                {
                    console.log("ADD", customer)

                    await api.post(`customersPickupPlaces`, { customerId: customer.value, pickupPlaceId: data.id }).then(() => {
                        dispatch({ type: "ADD_CUSTOMER_TO_PICKUP_PLACE" })
                    }).catch(err => { console.log(err); throw err })
                }
            })

            data.selectedCustomers.forEach(async (selectedCustomer) => {
                const shouldDelete = data.customers.every((customer) => selectedCustomer.id !== customer.value)

                if (shouldDelete)
                {
                    console.log("DELETE", selectedCustomer)

                    await api.delete(`customersPickupPlaces`, { customerId: selectedCustomer.id, pickupPlaceId: data.id }).then(() => {
                        dispatch({ type: "DELETE_CUSTOMER_FROM_PICKUP_PLACE" })
                    }).catch(err => { console.log(err); throw err })
                }
            })*/

        }).catch(err => { throw err })
    }
}
