// ** Initial State
const initialState = {
    allData: [],
    data: [],
    selectedServicePlan: null
}

const admins = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_SERVICE_PLANS':
            return { ...state, allData: action.data, data: action.data, selectedServicePlan: null }
        case 'FILTER_SERVICE_PLANS':
            return { ...state, data: action.data }
        case 'GET_SERVICE_PLAN':
            return { ...state, selectedServicePlan: action.selectedServicePlan }
        case 'UPDATE_SERVICE_PLAN':
            return { ...state, selectedServicePlan: null }
        case 'ADD_SERVICE_PLAN':
            return { ...state }
        default:
            return { ...state }
    }
}
export default admins
