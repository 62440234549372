// ** Initial State
const initialState = {
    selectedDepot: null
}

const depot = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_DEPOT':
            return { ...state, selectedDepo: action.data }
        case 'UPDATE_DEPOT':
            return { ...state }
        default:
            return { ...state }
    }
}

export default depot
