import { USER_ROLES } from '@store/actions/users'
import { toast, Slide } from 'react-toastify'
import Toast from '@components/aso/toast'

import { useDispatch, useSelector } from 'react-redux'

// ** Store & Actions
import { getDirectionURL, getAllPickups, filterData, FILTER_TYPES, addState } from '@store/actions/pickups'

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'Admin') return '/dashboard/admin'
  if (userRole === 'Driver') return '/dashboard/driver'
  if (userRole === 'Customer') return '/dashboard/customer'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export class Socket
{
    socket = undefined
    token = undefined
    dispatch = undefined
    retryCounter = 0

    constructor()
    {
        window.socket = this
    }

    handleMessage(event)
    {
        const data = JSON.parse(event.data)

        if (data.code === 1)
        {
            toast.success(
                <Toast color="info" header="Oznámení" text="Byl vytvořen nový expresní svoz. Přiřaďte k němu řidiče."/>,
                { transition: Slide, hideProgressBar: true, autoClose: false }
            )
        }
        else if (data.code === 0)
        {
            if (this.dispatch)
            {
                this.dispatch(getDirectionURL())
                this.dispatch(getAllPickups())
            }

            toast.success(
                <Toast color="info" header="Oznámení" text="Došlo ke změně trasy. Vygenerujte si trasu znovu."/>,
                { transition: Slide, hideProgressBar: true, autoClose: false }
            )
        }
    }

    connect()
    {
        if (this.socket && this.socket.readyState === WebSocket.OPEN)
        {
            return
        }

        if (this.retryCounter > 10)
        {
            return toast.error(
                <Toast color="danger" header="Chyba" text="Došlo k chybě při navázání spojení se serverem. Obnovte, prosím, stránku."/>,
                { transition: Slide, hideProgressBar: true, autoClose: 5000 }
            )
        }

        this.retryCounter++

        console.log("Connecting...", this.retryCounter)
        this.socket = new WebSocket(process.env.REACT_APP_BACKEND_ENDPOINT === "production" ? "wss://esprom.cz/api/notifications/ws" : "wss://aso.development.anafra.net/api/notifications/ws", ["token", this.token])

        this.socket.onopen = () => {
            console.log("Connected!")
            this.retryCounter = 0
        }

        this.socket.onerror = function(e) {
            console.error(e)
        }
        
        this.socket.onclose = (e) => {
            console.error("Disconnected!", e)

            setTimeout(() => { this.connect() }, 1000)
        }
        
        this.socket.onmessage = (event) => {
            this.handleMessage(event)
        }
    }

    setDispatch(dispatch)
    {
        this.dispatch = dispatch
    }

    setToken(token)
    {
        this.token = token
        this.connect()
    }
}

export const getLoggedUserData = () => (localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : {})
export const isLoggedUserCustomer = () => getLoggedUserData().role === USER_ROLES.CUSTOMER
export const isLoggedUserDriver = () => getLoggedUserData().role === USER_ROLES.DRIVER
export const isLoggedUserAdmin = () => {
    const store = useSelector(state => state.auth)

    let role = store.userData?.role

    if (isObjEmpty(store.userData) && localStorage.getItem("userData"))
    {
        role = JSON.parse(localStorage.getItem("userData")).role
    }

    return role === USER_ROLES.ADMIN
}
