import { api } from "@configs/apiConfig"

export const USER_ROLES = {
    ADMIN: "Admin",
    DRIVER: "Driver",
    CUSTOMER: "Customer"
}

// ** Get all users with specified role
export const getAllUsers = (role) => {
    return async dispatch => {
        let users = []

        if (role === USER_ROLES.DRIVER || role === USER_ROLES.ADMIN)
        {
            const response = await api.get(`users`)

            if (role === USER_ROLES.DRIVER)
            {
                users = response.data.filter((user) => user.role === USER_ROLES.DRIVER)
            }
            else if (role === USER_ROLES.ADMIN)
            {
                users = response.data.filter((user) => user.role === USER_ROLES.ADMIN)
            }
        }
        else if (role === USER_ROLES.CUSTOMER)
        {
            const response = await api.get(`customers`)
            users = response.data
        }

        users = users.filter((user) => user.isActive)

        dispatch({
            type: 'FETCH_USERS',
            data: users
        })

        if (role === USER_ROLES.CUSTOMER)
        {
            dispatch({
                type: 'FETCH_CUSTOMERS',
                data: users
            })
        }
        else if (role === USER_ROLES.DRIVER)
        {
            dispatch({
                type: 'FETCH_DRIVERS',
                data: users
            })
        }
    }
}

// ** Filter data gathered from getAllUsers()
export const filterData = (params) => {
    return async dispatch => {
        const users = params.data.filter((user) => {
            if (user.fullname)
            {
                return user.fullname.toLowerCase().includes(params.filter.toLowerCase())
            }

            return user.customerName.toLowerCase().includes(params.filter.toLowerCase())
        })
        dispatch({
            type: 'FILTER_USERS',
            data: users
        })
    }
}

// ** Get customer users with specified id
export const getCustomerUsers = (id) => {
    return async dispatch => {
        const response = await api.get(`users?customerId=${id}`)
        const users = response.data.filter((user) => user.isActive)

        dispatch({
            type: 'GET_CUSTOMER_USERS',
            selectedCustomerUsers: users
        })
    }
}

// ** Get user with specified id
export const getUser = (id, role) => {
    return async dispatch => {
        let user = {}

        if (role === USER_ROLES.DRIVER || role === USER_ROLES.ADMIN)
        {
            const response = await api.get(`users/${id}`)
            user = response.data
        }
        else if (role === USER_ROLES.CUSTOMER)
        {
            const response = await api.get(`customers/${id}`)
            user = response.data
        }

        if (!user.isActive)
        {
            throw "DEACTIVATED_USER"
        }

        dispatch({
            type: 'GET_USER',
            selectedUser: user
        })
    }
}

// ** Get pickup places belonging to customer specified with id
export const getCustomerPickupPlaces = (id) => {
    return async dispatch => {
        console.log(id)
        const response = await api.get(`customersPickupPlaces/pickupPlaces/${id}`)

        dispatch({
            type: 'GET_CUSTOMER_PICKUP_PLACES',
            selectedUserPickupPlaces: response.data
        })
    }
}

// ** Update user with specified id
export const updateUser = (data) => {
    return async dispatch => {
        console.log(data)

        if (data.role === USER_ROLES.DRIVER || data.role === USER_ROLES.ADMIN)
        {
            await api.put(`users/${data.id}`, data).then(() => {
                dispatch({ type: "UPDATE_USER" })
            }).catch(err => { throw err })
        }
        else
        {
            for (const key in data)
            {
                if (typeof data[key] !== "object" && !data[key].length) // PROSTOR PRO CHYBY!!! TODO:
                {
                    console.log("delete", data[key], key)
                    delete data[key]
                }
            }

            if (data.email && data.fullname)
            {
                await api.post(`customers/addUserToCustomer`, {
                    customerId: data.id,
                    addUserModel: {
                        fullname: data.fullname,
                        email: data.email
                    }
                }).catch(err => { console.log(err); throw err })
            }

            await api.put(`customers/${data.id}`, data).then(() => {
                dispatch({ type: "UPDATE_USER" })

                data.pickupPlaces.forEach(async (pickupPlace) => {
                    const shouldAdd = data.selectedPickupPlaces.every((selectedPickupPlace) => selectedPickupPlace.id !== pickupPlace.value)
                    
                    if (shouldAdd)
                    {
                        console.log("ADD", pickupPlace)
    
                        await api.post(`customersPickupPlaces`, { customerId: data.id, pickupPlaceId: pickupPlace.value }).then(() => {
                            dispatch({ type: "ADD_PICKUP_PLACE_TO_CUSTOMER" })
                        }).catch(err => { console.log(err); throw err })
                    }
                })
    
                data.selectedPickupPlaces.forEach(async (selectedPickupPlace) => {
                    const shouldDelete = data.pickupPlaces.every((pickupPlace) => selectedPickupPlace.id !== pickupPlace.value)
    
                    if (shouldDelete)
                    {
                        console.log("DELETE", selectedPickupPlace)
    
                        await api.delete(`customersPickupPlaces`, { customerId: data.id, pickupPlaceId: selectedPickupPlace.id }).then(() => {
                            dispatch({ type: "DELETE_PICKUP_PLACE_FROM_CUSTOMER" })
                        }).catch(err => { console.log(err); throw err })
                    }
                })
            }).catch(err => { throw err })
        }
    }
}

// ** Update user with specified id
export const createUser = (data) => {
    return async dispatch => {
        if (data.role === USER_ROLES.DRIVER || data.role === USER_ROLES.ADMIN)
        {
            await api.post(`users`, data).then(() => {
                dispatch({ type: "ADD_USER" })
            }).catch(err => { throw err })
        }
        else
        {
            if (data.isLogin)
            {
                data.addUserModel = { email: data.email, fullname: data.fullname }
                delete data.email
                delete data.fullname
            }

            for (const key in data)
            {
                if (typeof data[key] !== "object" && !data[key].length)
                {
                    delete data[key]
                }
            }

            await api.post(`customers`, data).then((response) => {
                dispatch({ type: "ADD_USER" })

                data.pickupPlaces.forEach(async (pickupPlace) => {
                    await api.post(`customersPickupPlaces`, { pickupPlaceId: pickupPlace.value, customerId: response.data.customerId }).then(() => {
                        dispatch({ type: "ADD_PICKUP_PLACE_TO_CUSTOMER" })
                    }).catch(err => { console.log(err); throw err })
                })
            }).catch(err => { throw err })
        }
    }
}

// ** Deletes user with specified id
export const deleteUser = (id, role, callbackToast) => {
    return async (dispatch, getState) => {
        if (role === USER_ROLES.DRIVER || role === USER_ROLES.ADMIN)
        {
            await api.delete(`users/${id}`).then(() => {
                dispatch({ type: "DELETE_USER" })
                dispatch(getAllUsers(role))
                callbackToast(false)
            }).catch(err => { callbackToast(true) })
        }
        else if (role === USER_ROLES.CUSTOMER)
        {
            await api.delete(`customers/${id}`).then(() => {
                dispatch({ type: "DELETE_USER" })
                dispatch(getAllUsers(role))
                callbackToast(false)
            }).catch(err => { callbackToast(true) })
        }
    }
}
